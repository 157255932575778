<template>
    <div class="content">
        <Top @handleLogin="handleLogin" @clickCollect="handleCollect" @clickSearch="handleSearch"></Top>

        <div class="main">
            <div class="detail">
                <div class="left">
                    <video autoplay
                        src="https://img.cdn.aliyun.dcloud.net.cn/guide/uniapp/%E7%AC%AC1%E8%AE%B2%EF%BC%88uni-app%E4%BA%A7%E5%93%81%E4%BB%8B%E7%BB%8D%EF%BC%89-%20DCloud%E5%AE%98%E6%96%B9%E8%A7%86%E9%A2%91%E6%95%99%E7%A8%8B@20200317.mp4"></video>
                </div>
                <div class="right font-color-wirte">
                    <div class="title">
                        {{ detail.title }}
                    </div>
                    <div class="label" v-for="(item, index) in detail.label" :key="index">
                        <div class="name">
                            {{ item.name }}
                        </div>
                    </div>
                    <div class="brief-introduction" v-focusable>
                        <div class="name">
                            {{ detail.introduction }}
                        </div>
                        <div class="jianjie">
                            <div class="jianjie-text">简介</div>
                            <div class="iconfont icon-xiangyou1"></div>
                        </div>
                    </div>
                    <div class="btn">
                        <div class="btn-center" @click="full()" v-focusable>
                            <div class="iconfont">
                                <img src="../assets/image/chaji.png" alt="">
                            </div>
                            <div class="name">全屏</div>
                        </div>
                        <div class="btn-center" v-focusable>
                            <div class="iconfont">
                                <img src="../assets/image/lj.png" alt="">
                            </div>
                            <div class="name">扫码登录</div>
                        </div>
                    </div>
                    <div class="desic">
                        登录可立即观看高清视频，请点击登录按钮后扫码登录
                    </div>
                </div>
            </div>
            <div class="play">
                <div class="row font-color">
                    <div class="title">播放列表</div>

                    <div class="chapters" v-if="detail.play.chapter.length != 0">
                        <div class="chapters-and-sections" :class="{ 'active': index == currentIndex }"
                            @onFocus="handleChaptersFocus(index)" v-focusable v-for="(item, index) in detail.play.chapter" :key="index">
                            <div class="info">
                                {{ item.title }}
                                <span class="division"></span>
                            </div>
                        </div>
                    </div>

                    <div class="gather">
                        全<span>{{ detail.play.count }}</span>集·登录观看高清视频
                    </div>
                </div>
            </div>

            <!-- 章节列表内容 -->
            <div class="scroll">
                <div class="list">
                    <div class="list-li font-color-wirte item5" v-focusable @on-focus="scrollfocus"  v-for="item in [1,2,3,4,5,6]" :key="item">
                        <div class="imgs">
                            <img controls class="video"
                                src="https://img0.baidu.com/it/u=422078137,1307526884&fm=253&fmt=auto&app=120&f=JPEG?w=1280&h=800" />
                            <div class="time">00:01:26</div>
                        </div>
                        <div class="title">1.1.1 搜索设置项</div>
                    </div>
                </div>
            </div>

            <div class="recommend">
                <div class="title font-color">为你推荐</div>
                <div class="recommend-list">
                    <img src="https://img0.baidu.com/it/u=422078137,1307526884&fm=253&fmt=auto&app=120&f=JPEG?w=1280&h=800"
                        alt="">

                    <div class="title font-color-wirte">龙凤花鸟字技法</div>
                </div>
            </div>
        </div>

        <div class="marsk" v-if="isShowMarsk">
            <div class="popup font-color-wirte">
                本课程系统的讲述了茶叶的冲泡，需要备具、备茶、备水，经沸水冲泡即可饮用。但要把茶固有的色、香、味
                本课程系统的讲述了茶叶的冲泡，需要备具、备茶、备水，经沸水冲泡即可饮用。但要把茶固有的色、香、味…
                本课程系统的讲述了茶叶的冲泡，需要备具、备茶、备水，经沸水冲泡即可饮用。但要把茶固有的色、香、味…
            </div>
        </div>
    </div>
</template>

<script>
import Top from '../components/common/Top.vue'
import { detail } from '../data/detail'
import { focusable } from "vue-tv-focusable";

export default {
    components: {
        Top
    },
    data() {
        return {
            detail: detail,
            did: null,
            isShowMarsk: false,
            keycode: 0, // 记录键值，默认： 上键:38,下键:40，具体看配置的值
            inScroll: 0, //确保焦点进入scrollEl和移出的时候只运行一次focusable.scrollEl；
            // 当前下标
            currentIndex: 0,
        }
    },

    created() {
        this.did = this.$route.params.id
        console.log(this.did, '======');

    },


    mounted() {
        // 局部滚动配置
        focusable.scrollSpeed = 100; // 两次按键小于100毫秒的话则当作一次事件。
        focusable.offsetDistance = 150; // 边缘距离为150

    },

    methods: {
        full() {
            const video = document.querySelector('video');
            video.requestFullscreen().then(() => {
                console.log('ok');
            })
        },

        // 点击登录跳转至登录页面
        handleLogin() {
            this.$router.push({ path: 'login' })
        },

        // 点击历史跳转至历史页面
        handleCollect() {
            this.$router.push({ path: 'collect' })
        },

        // 点击搜索跳转至该页面
        handleSearch() {
            this.$router.push({
                path: 'search'
            })
        },

        // 局部滚动
        scrollfocus(e) {
            console.log("scrollfocus");
            this.inScroll = 1;
            // scroll获取焦点的时候将自身的focusable去掉，将内部class为item5的dom添加focusable.
            document.querySelector(".scroll").removeAttribute(focusable.itemAttrname);
            document.querySelectorAll(".item5").forEach((e) => {
                e.setAttribute(focusable.itemAttrname, "");
            });
            // 将.scroll设置为滚动区域。
            focusable.scrollEl = document.querySelector(".scroll");
            // 按上下左右键分别定位到scroll里面的不同的el，具体跳转逻辑需要根据实际情况来
            if (this.keycode === 38) {
                const $item5list = document.querySelectorAll(".item5");
                focusable.next($item5list[$item5list.length - 10], false);
            } else if (this.keycode === 40 || this.keycode === 39) {
                focusable.next(document.querySelector(".item5"), false);
            } else if (this.keycode === 37) {
                const $item5list = document.querySelectorAll(".item5");
                focusable.next($item5list[9], false);
            }
            // 将.scroll距离边界100处的地方，防止和聚焦过程中滚动条滚动冲突，可以延时调用scrollTo,
            setTimeout(() => {
                focusable.scrollTo({
                    targetEl: document.querySelector(".scroll"),
                    offset: 100, // 距离边界100
                });
            }, 300);
        },

        handleChaptersFocus(index) {
            this.currentIndex = index
        }
    }
}
</script>

<style lang="less" scoped>
.focus {
    // transform: scale(1.1);
    box-shadow: 0 0 0.1rem #9aa7b1;
    border-radius: 0.08rem;
}

@import './css/detail.less';
</style>
