export const detail = {
    video: '',
    title: '智能手机应用',
    introduction: '该系列课程从老年人使用智能手机的实际需要出发，介绍基础篇、进阶篇和高级篇共三个篇章的内容，涵盖了...',
    label: [
        {
            id: 1,
            name: '手机'
        },
        {
            id: 2,
            name: '软件'
        },
        {
            id: 3,
            name: '云端'
        },
    ],

    play: {
        // 共多少集
        count: '13',
        // 章节
        chapter: [{
            id: 1,
            title: '第一章'
        },
        {
            id: 2,
            title: '第二章'
        },
        {
            id: 3,
            title: '第三章'
        }
        ],

        playList: [
            {
                id: 1,
                video: '',
                title: '1.1.1 搜索设置项'
            },
            {
                id: 2,
                video: '',
                title: '1.1.2 设置系统声音'
            },
            {
                id: 3,
                video: '',
                title: '1.1.3 密码指纹'
            },
            {
                id: 4,
                video: '',
                title: '1.1.4 搜索设置项'
            }
        ],

        // 为你推荐
        recommend: {
            title: '为你推荐',
            list:[{
                id: 1,
                videoImg: '',
                title: '龙凤花鸟字技法'
            },
            {
                id: 2,
                videoImg: '',
                title: '生活方式的自我防控'
            },
            {
                id: 3,
                videoImg: '',
                title: '穴位养生'
            },
            {
                id: 4,
                videoImg: '',
                title: '环保小制作'
            }
    
            ]
        }
    }
}